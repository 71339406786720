<template>
  <div class="referral-landing-page">
    <Menu :showMenu="showMenu" @hideMenu="hideSideMenu"></Menu>
    <div class="menu-and-logo sticky-header is-flex is-justify-content-space-between is-align-items-center">
      <img class="logo" src="@assets/images/common/logo.png" alt="logo" @click="toWordpressMainPage">
      <img src="@assets/images/referral/menu-hamburger.svg" alt="menu-icon" class="menu-icon" @click="showSideMenu">
    </div>
    <section class="top-section is-flex">
      <div class="left">
        <div class="logo-wrap">
          <img
            src="https://addy-public.s3.us-west-2.amazonaws.com/logo_prussian_blue.svg"
            alt="addy logo"
            class="addy-logo"
            @click="toWordpressMainPage">
        </div>
        <div class="showcase"></div>
      </div>
      <section class="signup-section right">
        <div class="navigations is-justify-content-space-between is-align-items-center">
          <img
            src="https://addy-public.s3.us-west-2.amazonaws.com/logo_prussian_blue.svg"
            alt="addy logo"
            class="addy-logo is-hidden-desktop"
            @click="toWordpressMainPage">
          <div class="is-flex is-align-items-center">
            <div
              v-for="(link, index) in wordpressLinks"
              :key="index"
              class="link"
              @click="toWordpressPages(link.link)"
            >
              {{link.title}}
            </div>
            <div class="link has-text-blue has-text-weight-semibold login" @click="login">Log in</div>
            <div
              class="link has-background-blue has-text-white has-text-centered has-text-weight-semibold is-uppercase signup"
              @click="toWordpressPages('https://www.addyinvest.ca/membership/')"
            >sign up</div>
          </div>
        </div>
        <section class="max-width">
          <div class="section-title has-text-blue has-text-weight-semibold is-uppercase">Become a member for a $25 bonus</div>
          <div class="section-text">
            Your friend invests in real estate with addy—and thinks you should too 🙌 !
            Become a member today to get a <span class="has-text-blue">$25</span> referral bonus into your addy wallet. Let’s share the joy of home ownership and #InvestTogether 🤝
          </div>
          <a
            class="referral-helpkit mb-6 is-block"
            href="https://support.addyinvest.com/miscellaneous/rgrXGZnnaYKsHBQoHEvhxr/when-will-my-referral-be-applied/oruGrpNjANApPM5CTMuS1n"
            target="_blank">Click
            <span class="has-text-primary">here</span>
            to learn when your referral will be applied
          </a>
          <SignupFormComponent origin="referralGateway"></SignupFormComponent>
          <div class="has-text-centered terms">
            <div>By clicking this button, you agree with our</div>
            <a href="https://addyinvest.ca/terms-of-use/" class="has-text-primary has-text-weight-semibold" target="_blank">Terms of Use</a>
          </div>
          <social-buttons mode="signup"/>
        </section>
      </section>
    </section>
    <section class="marquee-text has-background-white is-relative">
      <span class="banner">
        <span class="has-text-weight-semibold first-text">Invest in your future</span>
        <span class="has-text-weight-semibold">Invest in your future</span>
        <span class="tail">
          <span class="has-text-weight-semibold">Invest in your future</span>
          <span class="has-text-weight-semibold">Invest in your future</span>
        </span>
      </span>
    </section>
    <DesktopMarqueeText/>
    <section class="investment-example">
      <InvestmentExample></InvestmentExample>
    </section>
    <section class="footer-section">
      <Footer></Footer>
    </section>
  </div>
</template>
<script>
import SocialButtons from '@components/button/social-buttons.vue'
import Menu from './SideMenu.vue'
import SignupFormComponent from '../../signup/SignupForm.vue'
import DesktopMarqueeText from './DesktopMarqueeText.vue'
import InvestmentExample from './InvestmentExample.vue'
import Footer from './Footer.vue'
import { getReferrerName } from '@api/referral'
import { mapState } from 'vuex'

const LINKS = [
  {
    title: 'About',
    link: 'https://www.addyinvest.ca/about/',
  },
  {
    title: 'How it works',
    link: 'https://www.addyinvest.ca/how-it-works/',
  },
  {
    title: 'Membership',
    link: 'https://www.addyinvest.ca/membership/',
  },
  {
    title: 'Investments',
    link: 'https://www.addyinvest.ca/properties/',
  },
  {
    title: 'Blog',
    link: 'https://www.addyinvest.ca/blog/',
  },
]

export default {
  data() {
    return {
      wordpressLinks: Object.freeze(LINKS),
      showMenu: false,
      firstName: '',
    }
  },
  components: {
    Menu,
    SignupFormComponent,
    DesktopMarqueeText,
    InvestmentExample,
    Footer,
    SocialButtons
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    addyHandle() {
      return decodeURIComponent(this.$route.query.addyHandle)
    },
  },
  created() {
    this.getInfo()
  },
  mounted() {
    this.trackPageviews()
  },
  methods: {
    getInfo() {
      getReferrerName(this.addyHandle).then((result) => {
        if (!result.success) return
        this.firstName = result.data.firstName
      })
    },
    toWordpressPages(link) {
      window.location.href = link
    },
    toWordpressMainPage() {
      this.toWordpressPages('https://www.addyinvest.ca/')
    },
    showSideMenu() {
      this.showMenu = true
    },
    hideSideMenu() {
      this.showMenu = false
    },
    trackPageviews() {
      this.mixpanel.track('yy6mko7v', {
        addyHandle: this.addyHandle,
      })
    },
    login() {
      this.$router.push('/login')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.referral-landing-page {
  background-color: #eeebf7;
  .menu-and-logo {
    padding: 14px 20px 0;
    .logo {
      width: 50px;
    }
  }
  .top-section {
    .left {
      display: none;
    }
    .signup-section {
      padding: 0 20px 20px;
      .navigations {
        display: none;
      }
      .section-title {
        font-size: 32px;
        line-height: 38px;
        padding-top: 60px;
      }
      .section-text {
        font-size: 20px;
        line-height: 24px;
        margin-top: 12px;
        margin-bottom: 20px;
        letter-spacing: 0.01em;
      }
      .referral-helpkit {
        font-size: 16px;
      }
      .terms {
        font-size: 14px;
        margin-top: 14px;
        padding: 0 30px;
        line-height: 17px;
        > div {
          color: #939598;
        }
      }
    }
  }
  .marquee-text {
    overflow: hidden;
    height: 54px;
    .banner {
      position: absolute;
      white-space: nowrap;
      animation: scroll-left 14s linear infinite;
      span {
        font-size: 20px;
        line-height: 54px;
        color: #9185AE;
        &.first-text {
          margin-left: 60px;
          margin-right: 60px;
        }
        &.tail {
          position: absolute;
          left: 505px;
          span {
            margin-right: 60px;
          }
        }
      }
    }
  }
  .investment-example {
    padding: 20px;
    padding-bottom: 32px;
  }
  .footer-section {
    padding: 0 20px 60px;
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .referral-landing-page {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    padding-top: 24px;
    background-color: #F0EEF8;
    .top-section {
      .left {
        display: block;
        width: 45%;
        .logo-wrap {
          margin-bottom: 62px;
          .addy-logo {
            width: 73px;
            margin-left: calc((100vw - 1353px) / 2);
            cursor: pointer;
          }
        }
        .showcase {
          min-width: 264px;
          height: 655px;
          background: url('https://addy-public.s3.us-west-2.amazonaws.com/referral-landing-showcase.svg') right/contain no-repeat;
        }
      }
      .right {
        flex: 1;
        .navigations {
          display: flex;
          margin-bottom: 62px;
          min-width: 760px;
          .link {
            margin-right: 40px;
            font-size: 16px;
            cursor: pointer;
            &.login {
              margin-left: 24px;
              margin-right: 26px;
            }
            &.signup {
              width: 130px;
              height: 43px;
              border-radius: 12px;
              margin-right: 40px;
              line-height: 43px;
            }
          }
        }
        .max-width {
          max-width: 696px;
          .section-title {
            padding-top: 40px;
          }
        }
      }
    }
    .marquee-text {
      display: none;
    }
  }
}
@media screen and (max-width: 1393px) {
  .top-section .logo-wrap .addy-logo {
    margin-left: 20px!important;
  }
}
</style>
