<template>
  <div class="example-wrap">
    <div class="has-text-centered mb-5 image-at-top">
      <img src="https://addy-public.s3.us-west-2.amazonaws.com/referral-landing-showcase-new.png" alt="Willowglen mini-prop" class="prop-sticker">
    </div>
    <div class="section-title has-text-centered px-3 mb-3">
      If you invested
      <span class="has-text-blue has-text-weight-semibold">${{investment | formatNumber(0)}}</span>
      into a
      <span class="has-text-blue has-text-weight-semibold">commercial business park</span>,
      it could’ve turned into an estimated
      <span class="has-text-blue has-text-weight-semibold">${{exitValue | formatNumber}}</span>
    </div>
    <div class="is-flex">
      <div class="example-panel has-background-white">
        <div class="panel-title has-text-weight-semibold">This could’ve been yours 👀</div>
        <div class="instructions">Use the slider to see how one investment using addy could potentially grow 🌱</div>
        <div class="flex-container">
          <section class="dynamic-metrics">
            <div class="slider-title">Investment amount</div>
            <div class="display-amount has-text-centered">
              <span class="has-text-blue has-text-weight-semibold sup">$</span>
              <span class="has-text-blue value">{{investment | formatNumber(0)}}</span>
            </div>
            <b-slider
              v-model="investment"
              :min="1"
              :max="purchaseLimit"
              type="is-lavender"
              :tooltip="false"
              :rounded="true"
            ></b-slider>
            <div class="range is-flex is-justify-content-space-between">
              <span class="has-text-blue">$1</span>
              <span class="has-text-blue">${{ purchaseLimit }}</span>
            </div>
            <div class="metric-title">Ownership percentage</div>
            <div class="metric-value has-border has-text-blue">
              <span class="has-text-blue pr-2">{{ownershipPercentage | formatNumber(4)}}</span>%
            </div>
            <div class="metric-title">Investment term</div>
            <div class="metric-value has-border has-text-blue">
              <span class="has-text-blue pr-2">{{term}}</span>years
            </div>
            <div class="metric-title">Projected IRR</div>
            <div class="metric-value has-border has-text-blue">
              <span class="has-text-blue pr-2">{{irr | formatNumber(1)}}</span>%
            </div>
            <div class="metric-title">Total exit value (initial investment + profit)</div>
            <div class="metric-value has-text-blue">$
              <span class="has-text-blue pl-2">{{exitValue | formatNumber}}</span>
            </div>
          </section>
          <div class="graph-panel is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
            <section class="graph-captions is-inline-block has-text-left">
              <div class="optimistic">
                <span class="circle"></span>
                <span class="name">Optimistic: </span>
                <span class="value">${{exitValue * 1.1 | formatNumber}}</span>
              </div>
              <div class="realistic">
                <span class="circle"></span>
                <span class="name">Realistic: </span>
                <span class="value">${{exitValue | formatNumber}}</span>
              </div>
              <div class="pessimistic">
                <span class="circle"></span>
                <span class="name">Pessimistic: </span>
                <span class="value">${{exitValue * 0.9 | formatNumber}}</span>
              </div>
            </section>
            <section class="future-value-graph">
              <div class="px-2">
                <img src="../../../assets/images/referral/future-value-graph.png" alt="future value">
              </div>
              <div class="x-axis is-flex is-justify-content-space-between pt-2">
                <span>Year 0</span>
                <span>Year {{term}}</span>
              </div>
            </section>
          </div>
        </div>
        <div class="tooltip is-inline-flex is-align-items-center is-clickable" @click="showFutureValueModal = true">
          <img src="@assets/icons/info.svg" alt="tooltip">
          <span class="tooltip-label">About this calculator & graph</span>
        </div>
      </div>
      <div class="prop-sticker-wrap is-justify-content-center is-align-items-center">
        <img src="https://addy-public.s3.us-west-2.amazonaws.com/referral-landing-showcase-new.svg" alt="Willowglen mini-prop" class="prop-sticker">
      </div>
    </div>
    <StandardModal
      :showModal="showFutureValueModal"
      :closeModal="closeFutureValueModalAction"
      :buttonTappedAction="closeFutureValueModalAction"
      title="How it’s calculated"
      buttonTitle="Close"
    >
      <div class="modal-text">
        <div class="mb-4">Projections are based on the projected cash flow (expected distributions and estimated appreciation) of one of our existing properties – the Park at Willowglen.</div>
        <div class="mb-4">The ‘optimistic’ return is based on a +10% increase on the cash flows per year. The ‘pessimistic’ return is based on a -10% decrease on the cash flows per year.</div>
        <div class="mb-1">
          We cannot predict the real estate market, so actual results may differ and the graph is for visualization purposes only.
          You are not liable beyond your investment amount.
        </div>
      </div>
    </StandardModal>
  </div>
</template>
<script>
import { formatter } from '@utils/common-methods/numberFormatter'
import StandardModal from '@components/modal/standard-modal.vue'
import { getPropertyMetrics } from '@api/referral'

export default {
  data() {
    return {
      investment: 400,
      issuanceTotal: '510000',
      term: 10,
      roi: '155.89',
      irr: '11.7',
      purchaseLimit: 2500,
      showFutureValueModal: false,
    }
  },
  components: {
    StandardModal,
  },
  filters: {
    formatNumber(value, decimals = 2) {
      return formatter(value, decimals)
    },
  },
  computed: {
    ownershipPercentage() {
      return this.investment / this.issuanceTotal * 100
    },
    // exit value = investment+investment*ROI
    exitValue() {
      return this.investment * (1 + this.roi / 100)
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      // property The Park at Willowglen: hashid: 3dw1hy5; property_id: 7
      getPropertyMetrics(7).then((result) => {
        if (!result.success) return
        const { term, roi, irr, issuanceTotal, purchaseLimit } = result.data
        this.term = term
        this.roi = roi
        this.irr = irr
        this.issuanceTotal = issuanceTotal
        this.purchaseLimit = purchaseLimit
      })
    },
    closeFutureValueModalAction() {
      this.showFutureValueModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.example-wrap {
  padding-top: 10px;
  .section-title {
    font-size: 32px;
    line-height: 38px;
  }
  .prop-sticker {
    width: 220px;
  }
  .example-panel {
    border-radius: 12px;
    padding-top: 24px;
    margin-top: 30px;
    .panel-title {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 4px;
      padding: 0 22px;
    }
    .instructions {
      font-size: 16px;
      line-height: 19px;
      padding: 0 22px;
      letter-spacing: 0.03em;
    }
    .dynamic-metrics {
      padding: 24px 22px 0;
      .slider-title {
        font-size: 16px;
        line-height: 19px;
      }
      .display-amount {
        padding-top: 10px;
        padding-bottom: 7px;
        .sup {
          font-size: 16px;
          vertical-align: top;
          line-height: 26px;
          margin-right: 10px;
        }
        .value {
          font-size: 38px;
        }
      }
      .b-slider {
        margin-bottom: 7px;
      }
      .range {
        font-size: 14px;
      }
      .metric-title {
        font-size: 16px;
        line-height: 19px;
        margin-top: 20px;
        margin-bottom: 15px;
      }
      .metric-value {
        font-size: 16px;
        padding-bottom: 10px;
        &.has-border {
          border-bottom: 1px solid #F0EEF8;
        }
      }
    }
    .graph-captions {
      font-size: 16px;
      padding: 36px 0 50px 0;
      > div {
        margin-bottom: 8px;
      }
      .circle {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      .optimistic .circle {
        background: #3CDBC0;
      }
      .realistic .circle {
        background: #4A26AA;
      }
      .pessimistic .circle {
        background: #D79133;
      }
      .name {
        display: inline-block;
        margin-left: 8px;
        width: 100px;
      }
    }
    .future-value-graph {
      font-size: 14px;
      img {
        width: 100%;
        border-bottom: 1px solid #F0EEF8;
      }
      .x-axis {
        padding: 0 22px;
      }
    }
    .tooltip {
      padding-left: 22px;
      padding-bottom: 25px;
      margin-top: 28px;
      .tooltip-label {
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
  .prop-sticker-wrap {
    display: none;
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .image-at-top {
    display: none;
  }
  .example-wrap {
    max-width: 1353px;
    margin: 0 auto;
    .section-title {
      padding-top: 40px;
    }
    .example-panel {
      width: 758px;
      .flex-container {
        display: flex;
        .dynamic-metrics {
          width: 340px;
        }
        .graph-panel {
          flex: 1;
        }
      }
    }
    .prop-sticker-wrap {
      display: flex;
      flex: 1;
      img {
        width: 62%;
        max-width: 396px;
      }
    }
  }
}
</style>
<style scoped>
.b-slider >>> .b-slider-track {
  height: 9px;
  background: #F0EEF8;
}
.b-slider >>> .b-slider-fill {
  left: 4px !important;
  height: 3px;
  border-radius: 6px;
}
.b-slider >>> .b-slider-thumb {
  width: 24px;
  height: 24px;
  background-color: #9185AE;
  border: none;
}
</style>
