<template>
  <div class="desktop-marquee-text has-background-white is-relative">
    <span class="banner">
      <span class="has-text-weight-semibold first-text">Invest in your future</span>
      <span class="tail">
        <span class="has-text-weight-semibold" v-for="(text, index) in marqueeText" :key="index">{{text}}</span>
      </span>
    </span>
  </div>
</template>
<script>
export default {
  computed: {
    marqueeText() {
      const arr = []
      for (let index = 0; index < 20; index++) {
        arr.push('Invest in your future')
      }
      return arr
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.desktop-marquee-text {
  display: none;
  overflow: hidden;
  height: 54px;
  .banner {
    position: absolute;
    white-space: nowrap;
    animation: scroll-left 10s linear infinite;
    span {
      font-size: 20px;
      line-height: 54px;
      color: #9185AE;
      &.first-text {
        margin-left: 100px;
      }
      &.tail {
        position: absolute;
        left: 364px;
        span {
          margin-right: 100px;
        }
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .desktop-marquee-text {
    display: block;
  }
}
</style>
