import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * get referral details
 * @return {Promise}
 */
export const getReferralDetails = () => {
  return axios.get(getImbyRoute('/api/v1/referral/details'))
}

/**
 * Send Invites
 * @param {Object} payload emails, email_content
 * @return {Promise}
 */
export const sendInvites = (payload) => {
  return axios.post(getImbyRoute('/api/v1/referral/send_invites'), { referral: payload })
}

/**
 * get referral lists
 * @return {Promise}
 */
export const getReferralLists = () => {
  return axios.get(getImbyRoute('/api/v1/referral/list'))
}

/**
 * get referrer name
 * @param {String} addyHandle
 * @return {Promise}
 */
export const getReferrerName = (addyHandle) => {
  return axios.get(getImbyRoute(`/api/v1/public/referral/referrer_details?addy_handle=${addyHandle}`))
}

/**
 * get property metrics
 * @param {Number} propertyId
 * @return {Promise}
 */
export const getPropertyMetrics = (propertyId) => {
  return axios.get(getImbyRoute(`/api/v1/public/referral/property_metrics?property_id=${propertyId}`))
}
