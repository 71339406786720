<template>
  <ValidationObserver ref="signupForm" v-slot="{ valid }" class="is-inline-block signup-form-component">
    <ValidationProvider name="email" rules="required|email" v-slot="{ errors, passed, failed }">
      <div class="field email-field">
        <div :class="`control has-icons-right ${emailFieldIsLoading ? 'is-loading' : ''}`">
          <input :class="`input is-medium-height ${typeOfEmailField(passed, failed)}`" type="email" placeholder="Email" v-model.trim="email" @blur="checkUniquenessOfEmail(passed)">
          <span class="icon is-small is-right">
            <img
              v-show="failed || !emailAvailable && !emailFieldIsLoading"
              src="@assets/icons/exclamation.svg"
              alt="is-danger"
              style="width: 30px;"
              data-cy="mismatch-confirm-password-exclamation">
            <img v-show="passed && !emailFieldIsLoading && emailAvailable" src="@assets/icons/check.svg" alt="is-success" style="width: 16px;" data-cy="confirm-password-checkmark">
          </span>
        </div>
        <p class="help is-danger" v-show="failed || !emailAvailable">{{errors[0] || (emailValid ? 'That email is taken.' : 'Invalid email')}}</p>
      </div>
    </ValidationProvider>
    <ValidationProvider name="password" rules="required|password-complexity" v-slot="{ errors, passed, failed }" vid="password">
      <div class="field password-field">
        <div class="control has-icons-right">
          <input :class="`input is-medium-height ${classForField({ passed, failed })}`" :type="revealedPassword ? 'text' : 'password'" placeholder="Password" v-model.trim="password">
          <span class="icon is-small is-right" @click="revealedPassword = !revealedPassword">
            <img v-show="!revealedPassword" src="@assets/icons/eye.svg" alt="is-danger" class="icon-eye" data-cy="eye-on">
            <img v-show="revealedPassword" src="@assets/icons/eye-off.svg" alt="is-success" class="icon-eye" data-cy="eye-off">
          </span>
        </div>
        <p class="help is-danger" v-show="failed">{{errors[0]}}</p>
      </div>
    </ValidationProvider>
    <ValidationProvider name="confirmedPassword" rules="required|confirmed:password" v-slot="{ errors, passed, failed }">
      <div class="field confirmed-password-field">
        <div class="control has-icons-right">
          <input :class="`input is-medium-height ${classForField({ passed, failed })}`" type="password" placeholder="Confirm password" v-model.trim="confirmedPassword">
          <span class="icon is-small is-right">
            <img v-show="failed" src="@assets/icons/exclamation.svg" alt="is-danger" style="width: 30px;" data-testid="confirmation-error">
            <img v-show="passed" src="@assets/icons/check.svg" alt="is-success" style="width: 16px;" data-testid="confirmation-check">
          </span>
        </div>
        <p class="help is-danger" v-show="failed">{{confirmedPasswordErrorMsg(errors[0])}}</p>
      </div>
    </ValidationProvider>
    <div class="is-flex is-justify-content-space-between">
      <ValidationProvider name="country" rules="required" v-slot="{ errors, passed, failed }" class="is-inline-block countryOrProvince">
        <b-field :type="classForField({ passed,failed })" :message="errors[0]">
          <b-select name="country" placeholder="Country" v-model="country" @input="resetStateOrProvince" class="is-medium-height is-blue country">
            <option
              v-for="option in Countries"
              :value="option.code"
              :key="option.code"
            >{{ option.name }}</option>
          </b-select>
        </b-field>
      </ValidationProvider>
      <ValidationProvider name="stateOrProvince" rules="required" v-slot="{ errors, passed, failed }" class="is-inline-block countryOrProvince">
        <b-field :type="classForField({ passed, failed })" :message="errors[0]">
          <b-select
            name="stateOrProvince"
            :placeholder="stateOrProvincePlaceholder"
            v-model="stateOrProvince"
            :key="country === 'United States' ? 'stately-input' : 'province-input'"
            class="is-medium-height is-blue stateOrProvince"
          >
            <option
              v-for="option in (country === 'United States' ? UsaStates : CanadaProvinces)"
              :value="option.code"
              :key="option.code"
            >{{ option.name }}</option>
          </b-select>
        </b-field>
      </ValidationProvider>
    </div>
    <div class="has-text-centered">
      <b-checkbox v-model="subscribed" class="subscribed" type="is-blue">Sign up for newsletter</b-checkbox>
    </div>
    <PromiseLoadingButton
      :action="createAccount"
      :disabled="!valid || !emailAvailable || emailFieldIsLoading || !emailVerified || submittingSignup"
      :hasBoxShadow="false"
      class="button has-background-primary is-medium-height is-fullwidth is-rounded"
      data-cy="sign-up-btn"
    >Sign up</PromiseLoadingButton>
  </ValidationObserver>
</template>
<script>
import formMixins from '@utils/mixins/form-mixins.js'
import CanadaProvinces from '@utils/data/canada_provinces'
import UsaStates from '@utils/data/usa_states'
import Countries from '@utils/data/countries'
import PromiseLoadingButton from '@components/button/promise-loading-button.vue'
import addyAvailableRegions from '@utils/data/addy_available_regions'
import { initializePendo } from '@utils/common-methods/pendo'
import { embeddedInIos } from '@utils/common-methods/common'
import { signup, checkUniqueness, signupAddyPlusAdmin } from '@api/signup'
import { getMembershipDetails } from '@api/common'
import { mapState } from 'vuex'

export default {
  mixins: [formMixins],
  props: {
    origin: {
      type: String,
      default: 'signup/create',
    },
  },
  data() {
    return {
      email: undefined,
      password: undefined,
      confirmedPassword: undefined,
      country: undefined,
      stateOrProvince: undefined,
      subscribed: false,
      Countries,
      UsaStates,
      CanadaProvinces,
      emailFieldIsLoading: false,
      emailAvailable: true,
      emailValid: false,
      emailVerified: false,
      revealedPassword: false,
      addyAvailableRegions,
      submittingSignup: false,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    stateOrProvincePlaceholder() {
      return this.country === 'United States' ? 'State' : 'Province'
    },
    fromReferralGateway() {
      return this.origin === 'referralGateway'
    },
    isAddyPlusSignup() {
      return this.$route.path.startsWith('/addyPlus')
    },
    invitationToken() {
      return this.$route.query.token
    },
    addyHandle() {
      return this.$route.query.addyHandle
    },
  },
  components: {
    PromiseLoadingButton,
  },
  methods: {
    typeOfEmailField(passed, failed) {
      if (this.emailFieldIsLoading) return ''
      if (this.emailAvailable) {
        return this.classForField({ passed, failed })
      } else {
        return 'is-danger'
      }
    },
    resetStateOrProvince() {
      this.stateOrProvince = null
    },
    confirmedPasswordErrorMsg(error) {
      if (error === 'confirmedPassword is not valid.') return 'Password does not match'
      return error
    },
    checkUniquenessOfEmail(passed) {
      if (!passed) return
      this.emailFieldIsLoading = true
      checkUniqueness(this.email).then((data) => {
        if (!data.success) return
        const { available, valid } = data.data
        this.emailFieldIsLoading = false
        this.emailAvailable = available
        this.emailValid = valid
        this.emailVerified = true
      })
    },
    initializeAccountData(data) {
      localStorage.setItem('access_token', data.data.accessToken)
      localStorage.setItem('refresh_token', data.data.refreshToken)

      const { id, email } = data.data.investor
      initializePendo(id, email)
      this.setUserIdentityWithMixpanel()
      this.storeUserInfo(data.data.investor)
    },
    createAdminAccount() {
      signupAddyPlusAdmin({
        email: this.email,
        password: this.password,
        country: this.country,
        state_or_province: this.stateOrProvince,
        subscribed: this.subscribed,
        invitation_token: this.invitationToken,
      }).then((data) => {
        if (!data.success) return

        this.initializeAccountData(data)

        this.submittingSignup = false

        this.$router.push('/addyPlus/dashboard')
      }).catch((error) => {
        this.submittingSignup = false
        switch (error.response.data.error) {
        case 'mfa_required':
          this.toFactorAuthenticationPage(error.response.data.mfa_token)
          break
        }
      })
    },
    toFactorAuthenticationPage(mfaToken) {
      localStorage.setItem('mfa_token', mfaToken)
      this.$router.push('/addyPlus/factorAuthentication')
    },
    createRegularAccount() {
      signup({
        email: this.email,
        password: this.password,
        country: this.country,
        state_or_province: this.stateOrProvince,
        subscribed: this.subscribed,
        referrer_handle: this.addyHandle ? decodeURIComponent(this.addyHandle) : null,
      }).then((data) => {
        if (!data.success) return

        embeddedInIos() && window.webkit.messageHandlers.didSignUp.postMessage(data.data)

        this.initializeAccountData(data)

        this.trackSourceOfReferral()

        this.submittingSignup = false
        let url = ''
        if (this.addyAvailableRegions.includes(this.stateOrProvince)) {
          const tracker = this.fromReferralGateway ? '73yztawu' : 'toz8ryr7'
          this.mixpanel.track(tracker)
          url = `/signup/verify?email=${encodeURIComponent(this.email)}`
        } else {
          const tracker = this.fromReferralGateway ? 'vc3crb5r' : 'xpq5ai7g'
          this.mixpanel.track(tracker)
          url = `/signup/regionNotAvailable?email=${encodeURIComponent(this.email)}`
        }
        this.$router.push(url)
      })
    },
    createAccount() {
      this.submittingSignup = true
      this.$refs.signupForm.validate().then((success) => {
        if (!success) {
          this.submittingSignup = false
          return
        }
        this.isAddyPlusSignup ? this.createAdminAccount() : this.createRegularAccount()
      })
    },
    storeUserInfo(investor) {
      localStorage.setItem('investor', JSON.stringify(investor))
      this.initMembershipDetails()
    },
    initMembershipDetails() {
      getMembershipDetails().then((response) => {
        if (response.success) localStorage.setItem('membershipDetails', JSON.stringify(response.data))
      })
    },
    setUserIdentityWithMixpanel() {
      this.mixpanel.identify(this.email)
      this.$store.commit('mixpanel/setIdentity', true)
      this.mixpanel.people.set({
        '$email': this.email,
        'Sign up date': new Date(),
        'USER_ID': this.email
      })
    },
    trackSourceOfReferral() {
      const source = this.$route.query.source
      source && this.mixpanel.track('crpbs6nn', {
        source,
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.signup-form-component {
  width: 100%;
  .email-field,
  .password-field,
  .confirmed-password-field {
    margin-bottom: 8px;
  }
  .countryOrProvince {
    width: calc((100% - 8px) / 2);
  }
  .subscribed {
    font-size: 16px;
    margin: 21px 0;
  }
}
</style>
<style scoped>
.countryOrProvince >>> .select {
  display: inline-block;
  width: 100%;
}
.countryOrProvince >>> .select select {
  width: 100%;
}
.checkbox.subscribed >>> .check {
  width: 22px!important;
  height: 22px!important;
}
</style>
