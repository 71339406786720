<template>
  <div :class="`referral-side-menu modal px-5 ${showMenu ? 'is-active' : ''}`">
    <div class="modal-background" @click="hideMenu"></div>
    <div class="modal-content has-background-white">
      <img src="../../../assets/icons/close.svg" alt="close" class="close" @click="hideMenu">
      <div class="links">
        <div v-for="(link, index) in wordpressLinks" :key="index">
          <span class="num has-text-blue has-text-weight-semibold">0{{index + 1}}</span>
          <span class="link has-text-weight-semibold" @click="toWordpressPages(link.link)">{{link.title}}</span>
        </div>
      </div>
      <div class="login has-text-centered">
        <span class="has-text-weight-semibold" @click="login">Login</span>
      </div>
      <div class="signup has-text-centered">
        <span class="has-text-weight-semibold has-text-blue" @click="signup">Sign up</span>
      </div>
      <div class="terms has-text-centered">
        <a href="https://www.addyinvest.com/terms-of-use/" target="_blank">Terms of Use</a>
      </div>
      <div class="website has-text-centered">
        <a href="https://www.addyinvest.com/" target="_blank">addyinvest.com</a>
      </div>
      <div class="media is-flex is-justify-content-space-between is-align-items-center">
        <img src="../../../assets/icons/media-Facebook.svg" alt="Facebook" @click="toMedia('https://www.facebook.com/addyinvest/')">
        <img src="../../../assets/icons/media-Twitter.svg" alt="Twitter" @click="toMedia('https://twitter.com/addyinvest')">
        <img src="../../../assets/icons/media-Instagram.svg" alt="Instagram" @click="toMedia('https://www.instagram.com/addyinvest/')">
        <img src="../../../assets/icons/media-YouTube.svg" alt="YouTube" @click="toMedia('https://www.youtube.com/channel/UCktiu1eisP2a24Er3vG7-zA')">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      wordpressLinks: [
        {
          title: 'About',
          link: 'https://www.addyinvest.com/about/',
        },
        {
          title: 'How it works',
          link: 'https://www.addyinvest.com/how-it-works/',
        },
        {
          title: 'Membership',
          link: 'https://www.addyinvest.com/membership/',
        },
        {
          title: 'Investments',
          link: 'https://www.addyinvest.com/properties/',
        },
        {
          title: 'Blog',
          link: 'https://www.addyinvest.com/blog/',
        },
      ]
    }
  },
  props: {
    showMenu: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    hideMenu() {
      this.$emit('hideMenu')
    },
    toWordpressPages(link) {
      window.location.href = link
    },
    login() {
      this.$router.push('/login')
    },
    signup() {
      this.$router.push('/signup/create')
    },
    toMedia(url) {
      window.open(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.referral-side-menu {
  padding-right: 95px !important;
  padding-left: 0 !important;
  .modal-background {
    background-color: rgba(45, 41, 38, 0.25);
  }
  .modal-content {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: unset;
    margin: 0;
    .close {
      width: 30px;
      margin-top: 20px;
      margin-left: 20px;
    }
    .links {
      padding: 80px 0 18px 52px;
      > div {
        margin-bottom: 24px;
      }
      .num {
        font-size: 16px;
        line-height: 26px;
        padding-right: 12px;
        vertical-align: top;
      }
      .link {
        font-size: 28px;
        line-height: 33px;
      }
    }
    .login,
    .signup {
      font-size: 16px;
    }
    .login {
      margin-bottom: 18px;
    }
    .terms {
      font-size: 16px;
      margin-top: 42px;
    }
    .website {
      font-size: 16px;
      margin-top: 22px;
    }
    .media {
      padding: 25px 60px 140px;
      > img {
        width: 16px;
      }
    }
  }
}
</style>
