<template>
  <div class="footer-wrap">
    <div class="links is-flex is-justify-content-space-between is-align-items-center" v-if="hasLinks">
      <div class="social-media-links is-flex is-align-items-center">
        <img src="https://addy-public.s3.us-west-2.amazonaws.com/Instagram-logo.svg" alt="Instagram" @click="toNewPage('https://www.instagram.com/addyinvest/')">
        <img src="https://addy-public.s3.us-west-2.amazonaws.com/Facebook-logo.svg" alt="Facebook" @click="toNewPage('https://www.facebook.com/addyinvest/')">
        <img src="https://addy-public.s3.us-west-2.amazonaws.com/Twitter-logo.svg" alt="Twitter" @click="toNewPage('https://twitter.com/addyinvest')">
        <img src="https://addy-public.s3.us-west-2.amazonaws.com/YouTube-logo.svg" alt="YouTube" @click="toNewPage('https://www.youtube.com/channel/UCktiu1eisP2a24Er3vG7-zA')">
      </div>
      <div class="terms-of-use" @click="toNewPage('https://www.addyinvest.com/terms-of-use/')">Terms of Use</div>
    </div>
    <div class="disclaimer has-text-weight-semibold">Disclaimer</div>
    <div class="main-text is-italic">{{disclaimer}}</div>
    <div class="main-text is-italic mt-2" v-if="hasAtlasOneDisclaimer">{{atlasOneDisclaimer}}</div>
    <div class="statements has-text-weight-semibold mt-5">Forward-Looking Statements</div>
    <div class="main-text is-italic">{{statements}}</div>
  </div>
</template>
<script>
import { atlasOneDisclaimer, disclaimer, statements } from '@utils/data/statements.js'

export default {
  data() {
    return {
      disclaimer: Object.freeze(disclaimer),
      statements: Object.freeze(statements),
      atlasOneDisclaimer: Object.freeze(atlasOneDisclaimer),
    }
  },
  props: {
    hasLinks: {
      type: Boolean,
      default: true
    },
    hasAtlasOneDisclaimer: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    toNewPage(url) {
      window.open(url)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.footer-wrap {
  line-height: 20px;
  .links {
        margin-bottom: 36px;
    .social-media-links {
      img {
        margin-right: 24px;
        cursor: pointer;
      }
    }
    .terms-of-use {
      font-size: 16px;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .footer-wrap {
    padding: 0 8px;
    max-width: 1353px;
    margin: 0 auto;
  }
}
</style>
