var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"signupForm",staticClass:"is-inline-block signup-form-component",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"field email-field"},[_c('div',{class:("control has-icons-right " + (_vm.emailFieldIsLoading ? 'is-loading' : ''))},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],class:("input is-medium-height " + (_vm.typeOfEmailField(passed, failed))),attrs:{"type":"email","placeholder":"Email"},domProps:{"value":(_vm.email)},on:{"blur":[function($event){return _vm.checkUniquenessOfEmail(passed)},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()}}}),_c('span',{staticClass:"icon is-small is-right"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(failed || !_vm.emailAvailable && !_vm.emailFieldIsLoading),expression:"failed || !emailAvailable && !emailFieldIsLoading"}],staticStyle:{"width":"30px"},attrs:{"src":require("@assets/icons/exclamation.svg"),"alt":"is-danger","data-cy":"mismatch-confirm-password-exclamation"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(passed && !_vm.emailFieldIsLoading && _vm.emailAvailable),expression:"passed && !emailFieldIsLoading && emailAvailable"}],staticStyle:{"width":"16px"},attrs:{"src":require("@assets/icons/check.svg"),"alt":"is-success","data-cy":"confirm-password-checkmark"}})])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(failed || !_vm.emailAvailable),expression:"failed || !emailAvailable"}],staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0] || (_vm.emailValid ? 'That email is taken.' : 'Invalid email')))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|password-complexity","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"field password-field"},[_c('div',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}}],class:("input is-medium-height " + (_vm.classForField({ passed: passed, failed: failed }))),attrs:{"type":_vm.revealedPassword ? 'text' : 'password',"placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"icon is-small is-right",on:{"click":function($event){_vm.revealedPassword = !_vm.revealedPassword}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.revealedPassword),expression:"!revealedPassword"}],staticClass:"icon-eye",attrs:{"src":require("@assets/icons/eye.svg"),"alt":"is-danger","data-cy":"eye-on"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.revealedPassword),expression:"revealedPassword"}],staticClass:"icon-eye",attrs:{"src":require("@assets/icons/eye-off.svg"),"alt":"is-success","data-cy":"eye-off"}})])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirmedPassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('div',{staticClass:"field confirmed-password-field"},[_c('div',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.confirmedPassword),expression:"confirmedPassword",modifiers:{"trim":true}}],class:("input is-medium-height " + (_vm.classForField({ passed: passed, failed: failed }))),attrs:{"type":"password","placeholder":"Confirm password"},domProps:{"value":(_vm.confirmedPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmedPassword=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"icon is-small is-right"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticStyle:{"width":"30px"},attrs:{"src":require("@assets/icons/exclamation.svg"),"alt":"is-danger","data-testid":"confirmation-error"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticStyle:{"width":"16px"},attrs:{"src":require("@assets/icons/check.svg"),"alt":"is-success","data-testid":"confirmation-check"}})])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"help is-danger"},[_vm._v(_vm._s(_vm.confirmedPasswordErrorMsg(errors[0])))])])]}}],null,true)}),_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('ValidationProvider',{staticClass:"is-inline-block countryOrProvince",attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-field',{attrs:{"type":_vm.classForField({ passed: passed,failed: failed }),"message":errors[0]}},[_c('b-select',{staticClass:"is-medium-height is-blue country",attrs:{"name":"country","placeholder":"Country"},on:{"input":_vm.resetStateOrProvince},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}},_vm._l((_vm.Countries),function(option){return _c('option',{key:option.code,domProps:{"value":option.code}},[_vm._v(_vm._s(option.name))])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"is-inline-block countryOrProvince",attrs:{"name":"stateOrProvince","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-field',{attrs:{"type":_vm.classForField({ passed: passed, failed: failed }),"message":errors[0]}},[_c('b-select',{key:_vm.country === 'United States' ? 'stately-input' : 'province-input',staticClass:"is-medium-height is-blue stateOrProvince",attrs:{"name":"stateOrProvince","placeholder":_vm.stateOrProvincePlaceholder},model:{value:(_vm.stateOrProvince),callback:function ($$v) {_vm.stateOrProvince=$$v},expression:"stateOrProvince"}},_vm._l(((_vm.country === 'United States' ? _vm.UsaStates : _vm.CanadaProvinces)),function(option){return _c('option',{key:option.code,domProps:{"value":option.code}},[_vm._v(_vm._s(option.name))])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"has-text-centered"},[_c('b-checkbox',{staticClass:"subscribed",attrs:{"type":"is-blue"},model:{value:(_vm.subscribed),callback:function ($$v) {_vm.subscribed=$$v},expression:"subscribed"}},[_vm._v("Sign up for newsletter")])],1),_c('PromiseLoadingButton',{staticClass:"button has-background-primary is-medium-height is-fullwidth is-rounded",attrs:{"action":_vm.createAccount,"disabled":!valid || !_vm.emailAvailable || _vm.emailFieldIsLoading || !_vm.emailVerified || _vm.submittingSignup,"hasBoxShadow":false,"data-cy":"sign-up-btn"}},[_vm._v("Sign up")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }