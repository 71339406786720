<template>
    <div v-if="showSSO" class="has-text-centered has-text-primary social-buttons">
        <h4>{{buttonGroupHeading}}</h4>
        <a class="siwa-link" :href="siwaPath"><img src="@assets/images/signup/apple-sso.svg"></a>
        <a v-if="false" class="discord-link" :href="discordPath"><img src="@assets/images/signup/discord-sso.svg"></a>
        <a class="google-link" :href="googlePath"><img src="@assets/images/signup/google-sso.svg"></a>
    </div>
</template>

<script>
import { getSocialButtonPaths } from '@api/signup'
import { getFeatureToggle } from '@api/common'

export default {
  props: ['mode'],
  data() {
    return {
      siwaPath: '/',
      googlePath: '/',
      discordPath: '/',
      showSSO: false,
    }
  },
  mounted() {
    this.getToggle()
  },
  computed: {
    buttonGroupHeading() {
      if (this.mode === 'login') {
        return 'Or login with:'
      } else {
        return 'Or sign up with:'
      }
    }
  },
  methods: {
    getToggle() {
      getFeatureToggle('single_sign_on').then((result) => {
        if (result.success) this.showSSO = result.data.enabled
        if (this.showSSO) this.getButtonPaths()
      })
    },
    getButtonPaths() {
      getSocialButtonPaths().then((data) => {
        const addyHandle = this.$route.query.addyHandle

        let suffix = '&state=spa'

        if (addyHandle && addyHandle !== undefined) {
          suffix += '+' + addyHandle
        }

        this.siwaPath = data.data.siwa + suffix
        this.googlePath = data.data.google + suffix
        this.discordPath = data.data.discord + suffix
      })
    },
  },
}
</script>
<style lang="scss" scoped>

.social-buttons{
  h4{
    font-weight: 600;
    color: #2D2926;
    font-size: 1rem;
    padding:6px;
  }

  margin-top: 26px;

  a img{
    margin: 0 6px;
    display:inline-block;
    vertical-align: middle;
  }

  .google-link img{
    filter: drop-shadow(0px 0px 1.05px rgba(0, 0, 0, 0.084)) drop-shadow(0px 1.05px 1.05px rgba(0, 0, 0, 0.168));
  }
}

</style>
